import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mentions-légales"
    }}>{`Mentions légales`}</h1>
    <h2 {...{
      "id": "entreprise"
    }}>{`Entreprise`}</h2>
    <p>{`Karst Development SARL (France) (désignée ci-après par «la Société») est une Entreprise Individuelle, immatriculée au RCS de Nancy sous le numéro A434743225. Le siège social est situé 19 rue saint pierre, 54610 Morey, le numéro de TVA intracommunautaire est FR8543474322200014.
Le numéro de téléphone de la Société est : `}<a parentName="p" {...{
        "href": "tel:+33383815098",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`03 83 31 50 98`}</a>{`.
Directeur de la publication: `}<a parentName="p" {...{
        "href": "https://www.julienkarst.com",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`Julien Karst`}</a></p>
    <h2 {...{
      "id": "hébergement"
    }}>{`Hébergement`}</h2>
    <p>{`Le site `}<a parentName="p" {...{
        "href": "https://www.karst.tech/",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`www.www.karst.tech`}</a>{` est hébergé par la société Netlify, une marque déposée de « Netlify, Inc. », société à capitaux privés régie par le code fiscal des États-Unis d’Amérique.
610 22nd Street, Suite 315,
San Francisco, CA 94107
Site web : `}<a parentName="p" {...{
        "href": "https://www.netlify.com.",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`https://www.netlify.com.`}</a>{`
Le site `}<a parentName="p" {...{
        "href": "http://www.karst.tech",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`www.karst.tech`}</a>{` ci-après désignés par «le Site».`}</p>
    <h2 {...{
      "id": "politique-de-confidentialité"
    }}>{`Politique de confidentialité`}</h2>
    <p>{`Les informations recueillies dans les formulaires sont enregistrées dans un fichier informatisé par l’entreprise Château de Morey pour notamment afin de souscrire à l’une des offres en chambres d’hôtes ou location de salles, demander des renseignements à la Société, soumettre des commentaires quant au site ou aux offres. Ces informations personnelles nominatives sont nécessaires au bon traitement de la demande de l’Internaute par la Société.
Les données collectées seront communiquées aux seuls destinataires suivants : `}<a parentName="p" {...{
        "href": "https://www.typeform.com/",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`TypeForm Inc`}</a>{`, `}<a parentName="p" {...{
        "href": "https://mailchimp.com/",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`Mailchimp Inc`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.www.karst.tech/",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`Karst Development`}</a>{`.
Les données sont conservées pendant au minimum pendant toute la durée de l’échange entre l’Internaute et la Société, et au maximum 5 ans.
Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement.
Consultez le site `}<a parentName="p" {...{
        "href": "https://www.cnil.fr/",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`cnil.fr`}</a>{` pour plus d’informations sur vos droits.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      